define("ember-cli-skip-links/services/skip-links", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    activeElement: null,
    skipLinks: {},
    // skip links body component registry
    activeClass: 'is-visible',
    register: function register(skipLink) {
      var key = skipLink.get('key'); // key is optional

      key && this.set("skipLinks.".concat(key), skipLink);
    },
    unregister: function unregister(skipLink) {
      var key = skipLink.get('key'); // key is optional

      key && this.set("skipLinks.".concat(key), null);
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var $target = (0, _jquery.default)(event.target);

      if (this.activeElement && this.activeElement.toggle) {
        var _this$activeElement = this.activeElement,
            body = _this$activeElement.body,
            toggle = _this$activeElement.toggle;
      } else {
        var toggle = this.activeElement;
      }

      if (!($target.closest(toggle.$()).length || $target.closest(body && body.$()).length)) {
        this.deactivate();
      }
    },
    addOutsideClickListener: function addOutsideClickListener() {
      if (!this.handleOutsideClick.isBound) {
        (0, _jquery.default)(document).on('click touchstart', _jquery.default.proxy(this.handleOutsideClick, this));
        this.handleOutsideClick.isBound = true;
      }
    },
    removeOutsideClickListener: function removeOutsideClickListener() {
      (0, _jquery.default)(document).off('click touchstart', _jquery.default.proxy(this.handleOutsideClick, this));
      this.handleOutsideClick.isBound = false;
    },
    _deactivateSkipLink: function _deactivateSkipLink() {
      var skipLink = this.get('activeElement'); // remove when all skip links use key prop

      if (skipLink.toggle) {
        Ember.set(skipLink.toggle, 'isActive', false); // has body been registered?

        skipLink.body && Ember.set(skipLink.body, 'isActive', false);
      } else {
        Ember.set(skipLink, 'isActive', false);
      }
    },
    _activateSkipLink: function _activateSkipLink(toggle, body) {
      // has body been registered?
      body && Ember.setProperties(body, {
        isActive: true,
        didRenderOnce: true
      });
      Ember.setProperties(toggle, {
        isActive: true,
        didRenderOnce: true
      });
    },
    activate: function activate(skipLink) {
      var previousActiveElement = this.get('activeElement');

      if (skipLink.toggleOverlay) {
        this.showOverlay();
      }

      if (previousActiveElement) {
        this._deactivateSkipLink(previousActiveElement);
      }

      var body;
      var key = skipLink.get('key');

      if (key) {
        body = this.get("skipLinks.".concat(key)) || null;
        this.set('activeElement', {
          toggle: skipLink,
          body: body
        });
      } else {
        this.set('activeElement', skipLink);
      }

      this._activateSkipLink(skipLink, body);

      this.addOutsideClickListener();
      this.trigger(previousActiveElement ? 'toggle' : 'activate', skipLink);
    },
    deactivate: function deactivate() {
      var activeElement = this.get('activeElement');
      this.hideOverlay();

      this._deactivateSkipLink();

      this.set('activeElement', null);
      this.removeOutsideClickListener();
      this.trigger('deactivate', activeElement);
    },
    showOverlay: function showOverlay() {
      (0, _jquery.default)('#blocker').addClass(this.activeClass);
    },
    hideOverlay: function hideOverlay() {
      (0, _jquery.default)('#blocker').removeClass(this.activeClass);
    }
  });

  _exports.default = _default;
});